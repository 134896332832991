import { useCallback, useMemo, useState } from 'react';
import { BenefitingPerson, SalutationEnum, CountryEnum, AdminApi } from 'api';
import useApiCall from 'hooks/use-api-call';

export type BenefitingPersonEditType = BenefitingPerson & {
  action?: 'delete' | 'edit' | 'add';
  index?: number;
  expanded?: boolean;
};

const useEditBenefitingPerson = (
  investorId: string,
  benefitingOwners: BenefitingPerson[],
  onHide: () => void,
  fetchInvestorDetails?: () => void,
  setAcceptPep?: (val: boolean) => void,
  completeManualReview?: boolean,
) => {
  const [benefitingPersons, setBenefitingPersons] = useState<BenefitingPersonEditType[]>(benefitingOwners);

  const { makeAuthenticatedApi, withApi, error } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const emptyPerson = {
    salutation: SalutationEnum.MR,
    forename: '',
    surname: '',
    street: '',
    zip: '',
    city: '',
    country: CountryEnum.DEU,
    birthDate: null,
    birthPlace: '',
  };

  const expandBenefitingPerson = (index: number) => {
    setBenefitingPersons(
      benefitingPersons.map((existingPerson, indexPerson) =>
        index === indexPerson
          ? {
              ...existingPerson,
              expanded: !existingPerson.expanded,
              index: index,
            }
          : existingPerson,
      ),
    );
  };

  const deletePerson = (personIndex: number, event: any) => {
    event.stopPropagation();
    setBenefitingPersons(
      benefitingPersons?.map((person, index) => (index === personIndex ? { ...person, action: 'delete' } : person)),
    );
  };

  const isPersonExpanded = (index: number) => {
    return benefitingPersons.filter((person) => person?.expanded && person?.index === index).length > 0;
  };

  const addPerson = () => {
    setAcceptPep!(false);
    setBenefitingPersons([
      ...benefitingPersons,
      { ...emptyPerson, index: benefitingPersons.length, action: 'add', id: '', expanded: true },
    ]);
  };

  const onPersonChange = (values: BenefitingPersonEditType) => {
    setAcceptPep!(false);
    setBenefitingPersons(
      benefitingPersons.map((existingPerson, index) =>
        index === values.index
          ? {
              ...values,
              action: existingPerson.action === 'add' ? 'add' : 'edit',
            }
          : existingPerson,
      ),
    );
  };

  const getPersonByIndex = (index: number) => {
    return benefitingPersons.filter((person) => person.index === index)[0];
  };

  const isBenefitingPersonsEmpty = () => {
    return (
      benefitingPersons &&
      benefitingPersons.length > 0 &&
      !benefitingPersons.filter((person) => person.action !== 'delete')?.length
    );
  };

  const onDataSubmit = useCallback(() => {
    if (!investorId?.length || !benefitingPersons?.length) return;

    const personsToAdd = benefitingPersons.filter((person) => person.action === 'add');
    const personsToEdit = benefitingPersons.filter((person) => person.action === 'edit' && person.id);
    const personsToDelete = benefitingPersons.filter((person) => person.action === 'delete' && person.id);

    withApi(async () => {
      await Promise.all<any>([
        ...personsToAdd.map(
          async (person) =>
            await adminApi.adminInvestorsBenefitingPersonsCreate({
              id: investorId,
              benefitingPersonRequest: person,
            }),
        ),
        ...personsToDelete.map((person) =>
          adminApi.adminInvestorsBenefitingPersonsDestroy({
            id: investorId,
            personId: person.id as string,
          }),
        ),
        ...personsToEdit.map((person) =>
          adminApi.adminInvestorsBenefitingPersonsPartialUpdate({
            id: investorId,
            personId: person.id as string,
            patchedBenefitingPersonRequest: person,
          }),
        ),
      ]);

      if (completeManualReview) {
        await adminApi.adminInvestorsPartialUpdate({
          id: investorId,
          patchedAdminInvestorUpdateRequest: {
            manualKyc: true,
          },
        });
      }

      fetchInvestorDetails!();
      onHide();
    });
  }, [investorId, withApi, makeAuthenticatedApi, benefitingPersons]);

  return {
    benefitingPersons,
    expandBenefitingPerson,
    deletePerson,
    isPersonExpanded,
    addPerson,
    onPersonChange,
    getPersonByIndex,
    isBenefitingPersonsEmpty,
    onDataSubmit,
    error,
  };
};

export default useEditBenefitingPerson;
