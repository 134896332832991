import { BenefitingPerson } from 'api';
import React, { FunctionComponent, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalContent } from 'ui/molecules/modal';
import * as Styled from './styled';
import BenefitingPersonDataForm from './benefiting-person-form';
import Section from 'ui/atoms/section';
import Accordion from 'ui/atoms/accordion';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import useEditBenefitingPerson from './hooks/use-edit-benefiting-person';
export interface AddManualReviewModalProps {
  onHide: () => void;
  investorId: string;
  benefitingOwners?: BenefitingPerson[];
  fetchInvestorDetails?: () => void;
  completeManualReview?: boolean;
}

export interface BenefitingPersonsOverviewFields {
  acceptPep: boolean;
}

const BenefitingPersonAcceptPepForm = makeModalForm<BenefitingPersonsOverviewFields>();

const BenefitingPersonsModal: FunctionComponent<AddManualReviewModalProps> = (props) => {
  const { onHide, investorId, benefitingOwners = [], fetchInvestorDetails, completeManualReview } = props;

  const [acceptPep, setAcceptPep] = useState(true);

  const {
    benefitingPersons,
    expandBenefitingPerson,
    deletePerson,
    isPersonExpanded,
    addPerson,
    onPersonChange,
    getPersonByIndex,
    isBenefitingPersonsEmpty,
    onDataSubmit,
    error,
  } = useEditBenefitingPerson(
    investorId,
    benefitingOwners,
    onHide,
    fetchInvestorDetails,
    setAcceptPep,
    completeManualReview,
  );

  const getDisplayedContentOfPerson = (index: number, name: string) => {
    return (
      <Styled.PersonRowContainer>
        <div>
          <strong>
            <Translate name="kycComplianceDetails.addManualReview.person" />
            <span> {index + 1}</span>
          </strong>
          <div>{name}</div>
        </div>
        <Button variant="link" icon="trash" color="primary" onClick={(event) => deletePerson(index, event)}>
          <Translate name="common.delete" />
        </Button>
      </Styled.PersonRowContainer>
    );
  };

  return (
    <>
      <Modal onClose={onHide}>
        <ModalHeader>
          <Translate
            name={
              completeManualReview ? 'kycComplianceDetails.addManualReview.title' : 'benefitingPersons.editOnSummary'
            }
          />
        </ModalHeader>
        <ModalContent>
          <Section>
            <p>
              <Translate name="kycComplianceDetails.addManualReview.benefitingPersonsInfo1" />
            </p>
            <p>
              <Translate name="kycComplianceDetails.addManualReview.benefitingPersonsInfo2" />
            </p>
          </Section>
          {!isBenefitingPersonsEmpty() &&
            benefitingPersons.map((person, index) => {
              if (person.action === 'delete') return null;

              const isExpanded = isPersonExpanded(index);
              return (
                <div key={index}>
                  <Accordion
                    key={index}
                    labelComponent={getDisplayedContentOfPerson(
                      index,
                      `${person.forename || ''} ${person.surname || ''}`,
                    )}
                    expanded={isExpanded}
                    onChange={() => expandBenefitingPerson(index)}
                  >
                    <BenefitingPersonDataForm benefitingPerson={getPersonByIndex(index)} onChange={onPersonChange} />
                  </Accordion>
                </div>
              );
            })}
          <Section spacing="small">
            <Button variant="secondary" onClick={addPerson}>
              <Translate name="kycComplianceDetails.addManualReview.addPersonButton" />
            </Button>
          </Section>
          <BenefitingPersonAcceptPepForm onSubmit={onDataSubmit} error={error} initial={{ acceptPep: true }}>
            <BenefitingPersonAcceptPepForm.Group name="acceptPep" required={true}>
              <BenefitingPersonAcceptPepForm.Checkbox
                checked={acceptPep}
                onChange={() => {
                  setAcceptPep(!acceptPep);
                }}
              >
                <Translate name="kycComplianceDetails.addManualReview.benefitingPersonsCheckbox" />
              </BenefitingPersonAcceptPepForm.Checkbox>
            </BenefitingPersonAcceptPepForm.Group>
            {isBenefitingPersonsEmpty() && (
              <Hint variant="danger">
                <Translate name="kycComplianceDetails.addManualReview.noBenefitingPersonsError" />
              </Hint>
            )}
            <BenefitingPersonAcceptPepForm.Submit variant="primary" fluid={true} disabled={isBenefitingPersonsEmpty()}>
              <Translate
                name={
                  completeManualReview
                    ? 'kycComplianceDetails.addManualReview.confirmReviewButton'
                    : 'benefitingPersons.editOnSummary'
                }
              />
            </BenefitingPersonAcceptPepForm.Submit>
          </BenefitingPersonAcceptPepForm>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BenefitingPersonsModal;
