import React, { FunctionComponent, useEffect, useState } from 'react';
import Grid, { Col } from 'ui/atoms/grid';
import Header from 'ui/atoms/header';
import Input from 'ui/atoms/input';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import { VoucherFields } from '..';
import Spacer from 'ui/atoms/spacer';

interface VoucherUsesTotalProps {
  Form: any;
  values: VoucherFields;
  numberOfUsesTotalAmount: number | undefined;
  onChangeUsesTotal: (noOfUses: string, amount: number) => void;
}

export enum VoucherUsesTotalEnum {
  UNLIMITED = 'unlimited',
  LIMITED_TO = 'limitedTo',
}

const VoucherUsesTotal: FunctionComponent<VoucherUsesTotalProps> = ({
  Form,
  values,
  numberOfUsesTotalAmount,
  onChangeUsesTotal,
}) => {
  const [amount, setAmount] = useState(numberOfUsesTotalAmount || 1);

  useEffect(() => {
    onChangeUsesTotal(values.numberOfUsesTotal, amount);
  }, [values.numberOfUsesTotal, amount]);

  return (
    <Section spacing="medium">
      <Form.Group name="numberOfUsesTotal" required={true}>
        <Header size="small" spacing="medium">
          <Translate name="createVoucherForm.numberOfUsesTitle" />
        </Header>
        <Grid>
          <Col width={8} phone={12}>
            <strong>
              <Translate name={`createVoucherForm.numberOfUsesTotal.label`} />
            </strong>
            <Spacer y={2} />
            <Form.GroupToggle>
              {Object.values(VoucherUsesTotalEnum).map((option) => (
                <Form.Radio value={option} key={option}>
                  <Translate name={`createVoucherForm.${option}`} />
                </Form.Radio>
              ))}
            </Form.GroupToggle>
          </Col>
          <Col width={4} phone={12}>
            <strong>
              <Translate name={`createVoucherForm.amount`} />
            </strong>
            <Input
              type="number"
              min={1}
              disabled={values.numberOfUsesTotal === VoucherUsesTotalEnum.UNLIMITED}
              onChange={(e: any) => setAmount(e.target.value)}
              value={values.numberOfUsesTotal === VoucherUsesTotalEnum.UNLIMITED ? '' : amount.toString()}
            />
            <small>
              <Translate name={`createVoucherForm.numberOfUsesTotal.info`} />
            </small>
          </Col>
        </Grid>
      </Form.Group>
    </Section>
  );
};

export default VoucherUsesTotal;
